import './App.css';
import React, {useState,useEffect} from 'react'
import { Routes,Route } from 'react-router-dom'
import Home from './Home';
import Navbar from './Navbar';
import Footer from './Footer/Footer';
import Category from './Category';
import { userloggedIn } from './Context/Context';
import { userData } from './Context/Context';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import TermsOfService from './TermsOfService/TermsOfService';
import Product from './Product/Index';
import AllHosts from './AllHosts';

function App() {
  const [userdata,setuserdata] = useState({});
  const [islogged,setislogged] = useState(()=>{
    const token = localStorage.getItem('token');
    return !!token;
  });
  const [url,seturl] = useState('https://api.radionumbers.com/');
  const [loading,setloading] = useState(false);
  const isLoggedIn = async (e)=>{
    setloading(true);
    const res = await fetch(`${url}auth/verify-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      }
    });
    const data = await res.json();
    console.log(data);
    setloading(false);
    if(data.statusCode === 200){
      setuserdata(data.value);
    }else if(data.statusCode === 401){
      // logout();
    }
  }
  const logout =async ()=>{
    localStorage.removeItem("token");
    window.location.reload('/');
  }
  useEffect(()=>{
    isLoggedIn();
  },[])

  return (
    <userData.Provider value={{userdata,setuserdata,url,islogged,logout}}>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/all-hosts' element={<AllHosts/>} />
          <Route path='/category/:type' element={<Category/>} />
          <Route path='/ad/:id' element={<Product/>} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/terms-and-conditions' element={<TermsOfService />} />
          <Route path='*' element={<Home/>} />
        </Routes>
        <Footer />
      </div>
    </userData.Provider>
  );
}

export default App;
