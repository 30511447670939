import * as React from 'react';
import Classes from './styles/style.module.css'
import Host from './host'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './Accordin.css'

function Channel({channel,ind,advertisements,setfilteredAds,setemptyHost,setselectedChannel,setselectedHost}) {
    const channelClicked = ()=>{
        setemptyHost(false);
        setselectedHost('');
        setselectedChannel(channel.title);
        const filtered = advertisements.filter(ad => ad.channel.id === channel.id);
        setfilteredAds(filtered);
    }
    
  return (
    <>
        { channel?.host?.length === 0 ?
            <></>:
            <Accordion >
                <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                onClick={channelClicked}
                >
                <h5>
                    {channel.title}
                </h5>
                </AccordionSummary>
                <AccordionDetails>
                    { channel?.host && channel.host?.length > 0 ? channel?.host.map((host,ind)=>{
                        return(
                            <>
                                <Host key={ind} host={host} ind={ind} advertisements={advertisements} setfilteredAds={setfilteredAds} 
                                    setemptyHost={setemptyHost} setselectedHost={setselectedHost} />
                            </>
                        )}):
                        <div className='loading-text'> <h5>Host not available</h5> </div>
                    }
                </AccordionDetails>
            </Accordion>
        }
    </>
  )
}

export default Channel