import React, { useState } from 'react'
import Classes from './styles/style.module.css'

function PrivacyPolicy() {

    return (
        <>
            <form className={Classes.hero} >
                <h1>Radio Numbers Mobile Privacy Policy</h1>
                <div className={Classes.form_con} >
                    <h5>Last updated: April 15, 2024</h5>
                    <br />
                    <h5>
                        This Privacy Policy outlines the policies and procedures governing the collection, use, and disclosure of personal information when you utilize our service. It also elucidates your privacy rights and the legal protections afforded to you.
                        <br />
                        By using our Service, you consent to the collection and utilization of information in accordance with this Privacy Policy.
                    </h5>
                    <br />
                    <h5 className={Classes.major_title} >Interpretation and Definitions</h5>
                    <br />
                    <h5>
                        <span className={Classes.mini_title}> Interpretation:  </span>
                        Words with initial capitalization have specific meanings defined below, regardless of singular or plural form.
                    </h5>
                    <br />
                    <h5 className={Classes.major_title} >Definitions</h5>
                    <br />
                    <ol>
                        <li>
                            <h5>
                                <span className={Classes.mini_title}> Affiliate: </span>
                                Entity that controls, is controlled by, or is under common control with us.
                            </h5>
                        </li>
                        <li>
                            <h5>
                                <span className={Classes.mini_title}> Application: </span>
                                Refers to our software program, Radio Numbers.
                            </h5>
                        </li>
                        <li>
                            <h5>
                                <span className={Classes.mini_title}> Company: </span>
                                Refers to Radio Numbers.
                            </h5>
                        </li>
                        <li>
                            <h5>
                                <span className={Classes.mini_title}> Country: </span>
                                Canada.
                            </h5>
                        </li>
                        <li>
                            <h5>
                                <span className={Classes.mini_title}> Device: </span>
                                Any tool capable of accessing our Service.
                            </h5>
                        </li>
                        <li>
                            <h5>
                                <span className={Classes.mini_title}> Personal Data: </span>
                                Information relating to an identified or identifiable individual.
                            </h5>
                        </li>
                        <li>
                            <h5>
                                <span className={Classes.mini_title}> Service: </span>
                                Refers to our Application.
                            </h5>
                        </li>
                        <li>
                            <h5>
                                <span className={Classes.mini_title}> Service Provider: </span>
                                Natural or legal person processing data on our behalf.
                            </h5>
                        </li>
                        <li>
                            <h5>
                                <span className={Classes.mini_title}> Usage Data: </span>
                                Automatically collected data during Service usage.
                            </h5>
                        </li>
                        <li>
                            <h5>
                                <span className={Classes.mini_title}> You: </span>
                                Individual or legal entity accessing or using the Service.
                            </h5>
                        </li>
                    </ol>

                    <br />
                    <h5 className={Classes.major_title} > Collecting and Using Your Personal Data </h5>
                    <br />
                    <h5 className={Classes.major_title} > Types of Data Collected </h5>

                    <br />
                    <h5 className={Classes.mini_title} > Personal Data </h5>
                    <br />
                    <h5>During Service use, we may request certain personally identifiable information to contact or identify you. This includes, but is not limited to:</h5>
                    <ol>
                        <li>
                            <h5>
                                Email address
                            </h5>
                        </li>
                        <li>
                            <h5>
                                Phone number
                            </h5>
                        </li>
                        <li>
                            <h5>
                                Address, State, Province, ZIP/Postal code, City
                            </h5>
                        </li>
                    </ol>

                    <br />
                    <h5 className={Classes.major_title} > Usage Data </h5>
                    <br />
                    <h5>
                        We do not collect Usage Data that tracks behavior or interactions while using our Service.
                    </h5>
                    <br />
                    <h5>
                        Information Collected while Using the Application
                    </h5>
                    <br />
                    <h5>
                        Our Application does not collect precise location information about your device. We solely collect the specified personal data (email, name, phone number, and address) for service functionality and communication purposes.
                    </h5>
                    <br />
                    <h5 className={Classes.major_title} > Use of Your Personal Data</h5>
                    <br />
                    <h5>
                        We may use Personal Data for the following purposes:
                    </h5>
                    <br />
                    <ol>
                        <li>
                            <h5>
                                <span className={Classes.mini_title}> Service Maintenance:  </span>
                                Managing Your Account and providing access to Service functionalities.
                            </h5>
                        </li>
                        <li>
                            <h5>
                                <span className={Classes.mini_title}> Contacting You:  </span>
                                Via email, telephone calls, SMS, or other electronic communication forms for updates or informative communications related to the Service.

                            </h5>
                        </li>
                        <li>
                            <h5>
                                <span className={Classes.mini_title}> Business Transactions:  </span>
                                Utilizing information as part of mergers, acquisitions, or business transfers.
                            </h5>
                        </li>
                        <li>
                            <h5>
                                <span className={Classes.mini_title}> Other Purposes:  </span>
                                Requesting feedback, analyzing usage trends, and improving the Service.
                            </h5>
                        </li>
                    </ol>
                    <br/>
                    <h5 className={Classes.major_title} > Sharing of Your Personal Data </h5>
                    <br />
                    <h5>
                        We may share your personal information:
                    </h5>
                    <br />
                    <ol>
                        <li>
                            <h5>
                                <span className={Classes.mini_title}> With Service Providers: </span>
                                To facilitate Service provision and analyze usage.
                            </h5>
                        </li>
                        <li>
                            <h5>
                                <span className={Classes.mini_title}> During Business Transfers:  </span>
                                In connection with mergers, acquisitions, or transfers of business assets.

                            </h5>
                        </li>
                        <li>
                            <h5>
                                <span className={Classes.mini_title}>  With Affiliates:  </span>
                                When necessary, ensuring compliance with this Privacy Policy.
                            </h5>
                        </li>
                    </ol>
                    <br />
                    <h5 className={Classes.major_title} > Retention of Your Personal Data </h5>
                    <br />
                    <h5>
                        We will retain Personal Data only as long as necessary for the purposes outlined in this Privacy Policy. Usage Data is generally retained for shorter periods unless needed for security or Service improvement purposes.
                    </h5>
                    <br />
                    <h5>
                        It is solely the responsibility of the user or advertiser to ensure that the radio channel host or presenter has given consent for the use of their name, the name of their channel, or frequency.
                    </h5>
                    <br />
                    <h5>
                        The user shall not include any information irrelevant to their business.
                    </h5>
                    <br />
                    <h5 className={Classes.major_title} >
                        Contact Us
                    </h5>
                    <br />
                    <h5>
                        If you have questions regarding this Privacy Policy, please contact us:
                    </h5>
                    <br/>
                    <h5>
                        <span className={Classes.mini_title}>  By email: </span>
                        app.softcrafts@gmail.com
                    </h5>

                </div>
            </form>
        </>
    )
}

export default PrivacyPolicy
