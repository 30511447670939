import React,{useContext, useState} from "react";
import { NavLink,useNavigate } from 'react-router-dom'
import Classes from "./styles/style.module.css";
import logo from '../utils/icons/logo.jpg'
import login from '../utils/images/login.png'
import { LineIcon } from "../utils/SVGs/svgs";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { userData } from "../Context/Context";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LoginPopup = ({ handleCloseLoginPopup,handleOpenSignupPopup }) => {
    const {url} = useContext(userData);
    const [success,setsuccess] = useState(false);
    const [error,seterror] = useState(false);
    const [msg,setmsg] = useState('');
    const [loading,setloading] = useState(false);
    const [formdata,setformdata] = useState({
      fullName: '',
      email: '',
      password: '',
    });
    function changeValue(event){
      const {name,value} = event.target;
      setformdata({
        ...formdata,
        [name]: value,
      });
    }
    const handleSubmit = async (e)=>{
      e.preventDefault();
      setloading(true);
      const res = await fetch(`${url}auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formdata),
      });
      const data = await res.json();
      setloading(false);
      // console.log(data);
      if(data.statusCode === 200){
        localStorage.setItem("token",data.token);
        handleCloseLoginPopup();
        window.location.href = "/";
      }else if(data.statusCode === 208 || data.statusCode === 400){
        setmsg(data.message);
        seterror(true);
      }else{
        setmsg(data.message);
        seterror(true);
      }
      setTimeout(() => {
        setsuccess(false);
        seterror(false);  
      }, 3000);
    }
    const handleSignUpNow = ()=>{
        handleCloseLoginPopup();
        handleOpenSignupPopup();
    }
    const handlePopupClick = (e) => {
      e.stopPropagation(); // Prevent the click event from reaching the popupBackground
    };
    const handleBackgroundClick = (e) => {
      e.stopPropagation(); // Prevent the click event from reaching the popupBackground
      handleCloseLoginPopup();
    };

  return (
    <>
      <>
      <Snackbar open={success} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
          <Alert variant="filled" severity="success">
            {msg}
          </Alert>
        </Snackbar>
        <Snackbar open={error} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
          <Alert variant="filled" severity="error">
            {msg}
          </Alert>
        </Snackbar>
      </>
    <div className={Classes.popupBackground} onClick={handleBackgroundClick}>
      <div className={Classes.popup} onClick={handlePopupClick}>
        <div className={Classes.popupLeft}>
            <div>
                <div className={Classes.nav_img} >
                  <img src={logo} alt='logo' />
                </div>
                <h1>Welcome to <br /> RadioNumbers <br /> best platform </h1>
                <div className={Classes.login_img} >
                  <img src={login} alt='logo' />
                </div>
            </div>
        </div>
        <div className={Classes.popupRight}>
            <form onSubmit={handleSubmit} className={Classes.form} >
                <h1>Login</h1>
                <label> <h5>Email</h5> </label>
                <input 
                    placeholder='johndoe@mail.com'
                    name='email'
                    type='email'
                    value={formdata.email}
                    onChange={changeValue}
                    required
                />
                <label> <h5>Password</h5> </label>
                <input 
                    placeholder='Password'
                    name='password'
                    type='password'
                    value={formdata.password}
                    onChange={changeValue}
                    required
                />
                <button type='submit' > { loading?'Submitting':'Submit' } </button>
                <h5>
                  <NavLink to='/login'>Forget Password?</NavLink>
                </h5>
            </form>
            <div className={Classes.below_form_txt} >
                Don’t Have an account?
                <span onClick={handleSignUpNow} >Signup Now</span>
            </div>
        </div>
        <svg
          className={Classes.xMark}
          onClick={handleCloseLoginPopup}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
        >
          <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
        </svg>
        <div className={Classes.line_con} >
            <LineIcon />
        </div>
      </div>
    </div>
  </>
  );
};

export default LoginPopup;
