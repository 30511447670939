import React, { useState } from "react";
import Classes from "./styles/style.module.css";

function TermsOfService() {
  return (
    <>
      <form className={Classes.hero}>
        <h1>Radio Numbers Mobile Terms & Conditions</h1>
        <div className={Classes.form_con}>
          <h5>Effective Date: Wednesday, March 20, 2024</h5>
          <br />
          <h5>
            Radio Numbers are committed to protecting your privacy. This Privacy
            Policy (“Policy”) describes our practices in connection with
            information privacy on Personal Data we process through your
            individual use of the following services, products, and related
            mobile applications (collectively, the “Products”):
          </h5>
          <br />
          <h5 className={Classes.mini_title}>
            Radio Numbers Mobile & web Application
          </h5>
          <br />
          <h5>
            Before you use our Products, please carefully read through this
            Policy, and understand our purposes and practices for collection,
            and processing of your Personal Data, including how we use, store,
            share, and transfer Personal Data. In the Policy, you will also find
            ways to execute your rights of access, update, delete, or protect
            your Personal Data.
          </h5>
          <br />
          <h5 className={Classes.mini_title}>1. Acceptance of Terms:</h5>
          <br />
          <h5>
            By accessing or using Radio Numbers Mobile Application and related
            services (collectively, the "Products"), you agree to be bound by
            these Terms and Conditions. If you do not agree with any part of
            these Terms and Conditions, you may not access or use the Products.
          </h5>
          <br />
          <h5 className={Classes.mini_title}>2. Privacy Policy:</h5>
          <br />
          <h5>
            Your use of the Products is also governed by our Privacy Policy.
            Please review our Privacy Policy carefully to understand how we
            collect, use, and protect your Personal Data.
          </h5>
          <br />
          <h5 className={Classes.mini_title}>3. Personal Data:</h5>
          <br />
          <ol>
            <li>
                <h5>
                    You agree to provide accurate and complete information when
                    registering an account with us or using our Products.
                </h5>
            </li>
            <li>
                <h5>
                    You acknowledge that by using our Products, certain Personal Data
                    may be collected, processed, and stored as outlined in our Privacy
                    Policy.
                </h5>
            </li>
            <li>
                <h5>
                    You have the right to request access to, correction of, or
                    deletion of your Personal Data as described in our Privacy Policy.
                </h5>
            </li>
          </ol>
          
          <br />
          
          <h5 className={Classes.mini_title}>4. Third-Party Accounts:</h5>
          <br />
          <ol>
            <li>
                <h5>
                    You may have the option to log in to our Products using
                    third-party accounts (e.g., Google, Facebook). By doing so, you
                    authorize us to access and use certain information from these
                    accounts in accordance with our Privacy Policy.
                </h5>
            </li>
            <li>
                <h5>
                    You are responsible for maintaining the confidentiality and
                    security of your account credentials.
                </h5>
            </li>
          </ol>
          <br />

          <h5 className={Classes.mini_title}>5. Additional services based on storage permissions:</h5>
          <br />
          <ol>
            <li>
              <h5>
                The purpose is to ensure the stable operation of the App by
                utilizing the storage permission. After you give or indicate
                permission to read/write your mobile device’s storage, we will
                access pictures, files, crash log information, and other
                necessary information from your mobile device’s storage to
                provide you with functions, such as information publications, or
                record the crash log information locally.
              </h5>
            </li>
            <li>
              <h5>
                Please note that if you turn on any permission, you authorize us
                to collect and use relevant personal information to provide you
                with corresponding Services. Once you turn off any permission,
                we will take it as canceling the authorization, and we will no
                longer continue to collect Personal Data based on the
                corresponding permissions, and the related functions may be
                terminated. However, your decision to turn off the permission
                will not affect the previous collection and use of information
                based on your authorization.
              </h5>
            </li>
          </ol>
          <br/>
          <h5 className={Classes.mini_title} >6. Intellectual Property:</h5>
          <br/>
          <ol>
            <li>
                <h5>
                    All content and materials available through the Products,
                    including but not limited to text, graphics, logos, images, and
                    software, are the property of Radio Numbers or its licensors and are
                    protected by intellectual property laws.
                </h5>
            </li>
            <li>
                <h5>
                    You may not modify, reproduce, distribute, or create derivative
                    works based on the content and materials from the Products without
                    prior written consent from Radio Numbers.
                </h5>
            </li>
          </ol>
          <br />

          <h5 className={Classes.mini_title} >7. Limitation of Liability:</h5>
          <br />
          <ol>
            <li>
                <h5>
                    To the extent permitted by law, Radio Numbers shall not be liable
                    for any indirect, incidental, special, consequential, or punitive
                    damages arising out of or in connection with your use of the
                    Products.
                </h5>
            </li>
            <li>
                <h5>
                    In no event shall Radio Numbers' total liability to you for all
                    damages exceed the amount paid by you, if any, for accessing or
                    using the Products.
                </h5>
            </li>
          </ol>
          <br />

          <h5 className={Classes.mini_title}>8. Indemnification:</h5>
          <br />
          <h5 >
            You agree to indemnify, defend, and hold harmless Radio Numbers, its
            affiliates, officers, directors, employees, agents, licensors, and
            suppliers from and against any claims, losses, liabilities, damages,
            costs, or expenses arising out of or in connection with your use of
            the Products or violation of these Terms and Conditions.
          </h5>
          <br />
          <h5 className={Classes.mini_title} >9. Governing Law:</h5>
          <br />
          <h5>
            These Terms and Conditions shall be governed by and construed in
            accordance with the laws of [Jurisdiction], without regard to its
            conflict of law principles.
          </h5>
          <br />
          <h5 className={Classes.mini_title} >10. Changes to Terms:</h5>
          <br />
          <h5>
            Radio Numbers reserves the right to modify or amend these Terms and
            Conditions at any time. Any changes will be effective immediately
            upon posting the revised Terms and Conditions on the Products. Your
            continued use of the Products following the posting of changes
            constitutes your acceptance of such changes.
          </h5>
          <br />
          <h5 className={Classes.mini_title} >11. Severability:</h5>
          <br />
          <h5>
            If any provision of these Terms and Conditions is found to be
            invalid or unenforceable, that provision shall be limited or
            eliminated to the minimum extent necessary, and the remaining
            provisions shall remain in full force and effect.
          </h5>
          <br />
          <h5 className={Classes.mini_title} >12. Entire Agreement:</h5>
          <br />
          <h5>
            These Terms and Conditions constitute the entire agreement between
            you and Radio Numbers regarding your use of the Products and
            supersede all prior or contemporaneous agreements, communications,
            and proposals, whether oral or written, between you and Radio
            Numbers.
          </h5>
        </div>
      </form>
    </>
  );
}

export default TermsOfService;
