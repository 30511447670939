import React,{useState,useContext} from "react";
import { NavLink,useNavigate } from 'react-router-dom'
import Classes from "./styles/style.module.css";
import logo from '../utils/icons/logo.jpg'
import login from '../utils/images/login.png'
import { LineIcon } from "../utils/SVGs/svgs";
import { userData } from "../Context/Context";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const SignupPopup = ({ handleOpenLoginPopup,handleCloseSignupPopup }) => {
    const {url} = useContext(userData);
    const [check,setcheck] = useState(false);
    const [success,setsuccess] = useState(false);
    const [error,seterror] = useState(false);
    const [passwordError,setpasswordError] = useState(false);
    const [msg,setmsg] = useState('');
    const [loading,setloading] = useState(false);
    const [formdata,setformdata] = useState({
      Image_url: '',
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      contact: '',
      country: '',
      city: '',
      business_address: '',
      postal_code: '',
      province: '',
    });
    function changeValue(event){
      const {name,value} = event.target;
      setformdata({
        ...formdata,
        [name]: value,
      });
    }
    const handleSubmit = async (e)=>{
      e.preventDefault();
      if(formdata.password.length<6 || formdata.password.length>15){
        setpasswordError(true);
        setTimeout(() => {
          setpasswordError(false);
        }, 3000);
        return;
      }
      setloading(true);
      const res = await fetch(`${url}auth/registration-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formdata),
      });
      const data = await res.json();
      setloading(false);
      if(data.statusCode === 201){
        setmsg(data.message);
        setsuccess(true);
        setTimeout(() => {
          handleCloseSignupPopup();
          handleOpenLoginPopup();
        }, 2000);
      }else if(data.statusCode === 208 || data.statusCode === 400){
        setmsg(data.message);
        seterror(true);
      }else{
        seterror(true);
      }
      setTimeout(() => {
        setsuccess(false);
        seterror(false);  
      }, 3000);
    }
    const handleLoginNow = ()=>{
      handleCloseSignupPopup();
      handleOpenLoginPopup();
    }
    const handlePopupClick = (e) => {
      e.stopPropagation(); // Prevent the click event from reaching the popupBackground
    };
    const handleBackgroundClick = (e) => {
      e.stopPropagation(); // Prevent the click event from reaching the popupBackground
      handleCloseSignupPopup();
    };

  return (
    <>
      <>
        <Snackbar open={success} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
          <Alert variant="filled" severity="success">
            {msg}
          </Alert>
        </Snackbar>
        <Snackbar open={error} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
          <Alert variant="filled" severity="error">
            {msg}
          </Alert>
        </Snackbar>
        <Snackbar open={passwordError} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
          <Alert variant="filled" severity="error">
            Password length should be between 5 to 15 characters
          </Alert>
        </Snackbar>
      </>
      <div className={Classes.popupBackground} onClick={handleBackgroundClick}>
        <form onSubmit={handleSubmit} className={Classes.popup} onClick={handlePopupClick}>
          <h1>Sign Up</h1>
          <div>   
            <div className={Classes.popupRight}>
              <div className={Classes.form} >
                <label> <h5>First Name</h5> </label>
                <input 
                    placeholder='John'
                    name='first_name'
                    type='text'
                    value={formdata.first_name}
                    onChange={changeValue}
                    required
                />
                <label> <h5>Last Name</h5> </label>
                <input 
                    placeholder='Doe'
                    name='last_name'
                    type='text'
                    value={formdata.last_name}
                    onChange={changeValue}
                    required
                />
                <label> <h5>Email</h5> </label>
                <input 
                    placeholder='JohnDoe@hotmail.com'
                    name='email'
                    type='email'
                    value={formdata.email}
                    onChange={changeValue}
                    required
                />
                <label> <h5>Password</h5> </label>
                <input 
                    placeholder='Password'
                    name='password'
                    type='password'
                    value={formdata.password}
                    onChange={changeValue}
                    required
                />
                <label> <h5>Contact</h5> </label>
                <input 
                    placeholder='Contact'
                    name='contact'
                    type='number'
                    value={formdata.contact}
                    onChange={changeValue}
                    required
                />
                <label> <h5>Country</h5> </label>
                <input 
                    placeholder='Country'
                    name='country'
                    type='text'
                    value={formdata.country}
                    onChange={changeValue}
                    required
                />
                {/* <div className={Classes.nav_img} >
                  <img src={logo} alt='logo' />
                </div>
                <h1>Welcome to <br /> RadioNumbers <br /> best platform </h1>
                <div className={Classes.login_img} >
                  <img src={login} alt='logo' />
                </div> */}
              </div>
            </div>
            <div className={Classes.popupRight}>
              <div className={Classes.form} >
                <label> <h5>City</h5> </label>
                <input 
                    placeholder='City'
                    name='city'
                    type='text'
                    value={formdata.city}
                    onChange={changeValue}
                    required
                />
                <label> <h5>Business Address</h5> </label>
                <input 
                    placeholder='Business Address'
                    name='business_address'
                    type='text'
                    value={formdata.business_address}
                    onChange={changeValue}
                    required
                />
                <label> <h5>Postal Code</h5> </label>
                <input 
                    placeholder='Postal Code'
                    name='postal_code'
                    type='text'
                    value={formdata.postal_code}
                    onChange={changeValue}
                    required
                />
                <label> <h5>Province</h5> </label>
                <input 
                    placeholder='Province'
                    name='province'
                    type='text'
                    value={formdata.province}
                    onChange={changeValue}
                    required
                />
                <div className={Classes.checkbox} >
                  <input
                    className={Classes.check}
                    name='checkbox'
                    type='checkbox'
                    checked={check}
                    onChange={()=>{setcheck(!check)}}
                    required
                  />
                  <div className={Classes.below_form_txt} style={{textAlign:'start'}} >
                    I agreed to the 
                      <span> <NavLink to='/privacy-policy' target="_blank" >Privacy Policy</NavLink> </span>
                    and 
                      <span><NavLink to='/terms-and-conditions' target="_blank" >Terms & Conditions</NavLink></span>
                  </div>
                </div>
                <button type='submit' disabled={!check} style={{backgroundColor:check?'':'#eddcff'}} > { loading? 'Submitting...' : 'Submit' } </button>
                <h5 onClick={handleLoginNow} >
                  Already have an account?
                </h5>
              </div>
              <div className={Classes.below_form_txt} >
                Login instead here? 
                  <span onClick={handleLoginNow} >Login Now</span>
              </div>
            </div>
          </div>
          <svg
            className={Classes.xMark}
            onClick={handleCloseSignupPopup}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
          >
            <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
          </svg>
          <div className={Classes.line_con} >
            <LineIcon />
          </div>
        </form>
      </div>
    </>
  );
};

export default SignupPopup;
