import React from 'react'
import Classes from './styles/style.module.css'
import img from '../../utils/images/image.webp'
import { NavLink } from 'react-router-dom'

function Card({ad}) {
  return (
    <>
        <NavLink to={`/ad/${ad.id}`} target='_blank' className={Classes.card} >
            <div className={Classes.card_img} >
              <img src={ad?.image_url[0]} className={Classes.card_inner_img} />
            </div>
            <div className='flex_between2 g5px mxheght5' >
              <h5> {ad.title} </h5>
              <h3> {ad.channel?.type} </h3>
            </div>
        </NavLink>
    </>
  )
}

export default Card
