import React from 'react'
import Classes from './styles/style.module.css'
import Typography from '@mui/material/Typography';

function Host({host,ind,advertisements,setfilteredAds,setemptyHost,setselectedHost}) {
    const hostClicked = ()=>{
        setselectedHost(host.name)
        const filtered = advertisements.filter(ad => ad.host.id === host.id);
        setfilteredAds(filtered);
        if(filtered.length === 0){
            setemptyHost(true);
        }else{
            setemptyHost(false);
        }
    }
  return (
    <>
        <Typography
            onClick={hostClicked}
            className='pointer'
        >
            <h5 className={Classes.channel_active} > { host.name } </h5>
        </Typography>
    </>
  )
}

export default Host
