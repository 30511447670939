import React, { useContext, useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import Classes from './styles/style.module.css'
import { MagnifierIcon, RightArrowIcon } from '../utils/SVGs/svgs'
import img from '../utils/images/image.webp'
import Card from '../Components/Card/Card'
import Slider from '../Components/Slider/Slider'
import { userData } from '../Context/Context'

function Home() {
    const { url } = useContext(userData);
    const [advertisements, setadvertisements] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [AMadvert, setAMadvert] = useState([]);
    const [FMadvert, setFMadvert] = useState([]);
    const getAdvertisementData = async () => {
        const res = await fetch(`${url}advertisement/active`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            }
        });
        const data = await res.json();
        console.log(data);
        if (data.statusCode === 200) {
            const filteredAdstoAM = data.value.filter(ad => ad.channel.type.toLowerCase() === 'am');
            const filteredAdstoFM = data.value.filter(ad => ad.channel.type.toLowerCase() === 'fm');
            setadvertisements(data.value);
            setAMadvert(filteredAdstoAM);
            setFMadvert(filteredAdstoFM);
        }
    }
    useEffect(() => {
        getAdvertisementData();
    }, [])

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredAdvertisements = advertisements.filter(ad => ad.title.toLowerCase().includes(searchQuery.toLowerCase()));

    return (
        <>
            <section className={Classes.home} >
                <div className={Classes.search} >
                    <input
                        name='search'
                        placeholder='Search to match the title of ads...'
                        type='text'
                        value={searchQuery}
                        onChange={handleSearch}
                    />
                    <div className={Classes.search_icon} >
                        <MagnifierIcon />
                    </div>
                </div>
                {searchQuery ?
                    <div className={Classes.main_category}>
                        <div className='flex_between mb10'>
                            <h2>Search Results</h2>
                        </div>
                        <div className={Classes.search_slider}>
                            {filteredAdvertisements && filteredAdvertisements.length > 0 ? filteredAdvertisements.map((ad, ind) => {
                                return (
                                    <Card key={ind} ad={ad} />
                                )
                            }) :
                                <h5>No matching advertisements found.</h5>
                            }
                        </div>
                    </div>
                    : <></>
                }
                <div className={Classes.category_list} >
                    <NavLink to={'/category/am'} target='_blank' className={Classes.category_elem} >
                        <h5 className={Classes.active_elem} >ALL</h5>
                    </NavLink>
                    <NavLink to={'/category/am'} target='_blank' className={Classes.category_elem} >
                        <h5>AM</h5>
                    </NavLink>
                    <NavLink to={'/category/fm'} target='_blank' className={Classes.category_elem} >
                        <h5>FM</h5>
                    </NavLink>
                </div>
                <Slider advertisements={advertisements} />
                <div className={Classes.all_categories} >
                    <h2>Explore all Categories</h2>
                    <div className={Classes.ac_con} >
                        <NavLink to={'/category/all'} target='_blank' className={`${Classes.ac_elem}`} >
                            <h4>ALL</h4>
                        </NavLink>
                        <NavLink to={'/category/am'} target='_blank' className={Classes.ac_elem} >
                            <h4>AM</h4>
                        </NavLink>
                        <NavLink to={'/category/fm'} target='_blank' className={Classes.ac_elem} >
                            <h4>FM</h4>
                        </NavLink>
                        <NavLink to={'/all-hosts'} target='_blank' className={Classes.ac_elem} >
                            <h4>All Hosts</h4>
                        </NavLink>
                        <NavLink className={Classes.ac_elem} >
                            <h4>Live</h4>
                        </NavLink>
                    </div>
                </div>
                <div className={Classes.main_category} >
                    <div className='flex_between mb10' >
                        <h2>Advertiser on FM</h2>
                        <NavLink to={'/category/fm'} target='_blank' className='flex_align pointer' >
                            <h5 className='primary_color' >Show All</h5>
                            <RightArrowIcon />
                        </NavLink>
                    </div>
                    <div className={Classes.category_slider} >
                        {FMadvert && FMadvert.length > 0 ? FMadvert.slice(0, 6).map((ad, ind) => {
                            return (
                                <>
                                    <Card key={ind} ad={ad} />
                                </>
                            )
                        }) :
                            <div className='loading-text'> <h5>No FM Ad Available</h5> </div>
                        }
                    </div>
                </div>
                <div className={Classes.main_category} >
                    <div className='flex_between mb10' >
                        <h2>Advertiser on AM</h2>
                        <NavLink to={'/category/am'} target='_blank' className='flex_align pointer' >
                            <h5 className='primary_color' >Show All</h5>
                            <RightArrowIcon />
                        </NavLink>
                    </div>
                    <div className={Classes.category_slider} >
                        {AMadvert && AMadvert.length > 0 ? AMadvert.map((ad, ind) => {
                            return (
                                <>
                                    <Card key={ind} ad={ad} />
                                </>
                            )
                        }) :
                            <div>No AM Ad Available</div>
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default Home
