import React, { useRef, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './styles.css';
import { Pagination, Navigation, FreeMode } from 'swiper/modules';
import { LeftArrowSliderIcon, RightArrowSliderIcon } from '../../utils/SVGs/svgs';
import SlideComp from './SlideComp';

export default function Slider({ advertisements }) {
  const [slidesPerView, setSlidesPerView] = useState(6);
  const [spaceBetween, setspaceBetween] = useState(20);
  const [showPagination, setShowPagination] = useState(window.innerWidth >= 480);
  const swiperRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 700 && window.innerWidth < 900) {
        setSlidesPerView(5);
        setspaceBetween(15);
      } else if (window.innerWidth >= 480 && window.innerWidth < 700) {
        setSlidesPerView(4);
        setspaceBetween(10);
      } else if (window.innerWidth >= 320 && window.innerWidth < 480) {
        setSlidesPerView(3);
        setspaceBetween(5);
      } else {
        setSlidesPerView(6);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    const intervalId = setInterval(() => {
      if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
        swiperRef.current.swiper.slideNext();
      }
    }, 5000);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const slideToNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const slideToPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  return (
    <>
      <Swiper
        ref={swiperRef}
        slidesPerView={slidesPerView}
        spaceBetween={30}
        freeMode={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false
        }}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Pagination]}
        // className="mySwiper"
        navigation={{ prevEl: '.swiper-button-prev', nextEl: '.swiper-button-next' }}
        className="mySwiper"
      >
        {advertisements.map((ad) => (
          <SwiperSlide key={ad.id}>
            <NavLink to={`/ad/${ad.id}`} target='_blank' >
              <img className='slider_inner_img' src={ad.image_url[0]} alt={ad.title} />
            </NavLink>
          </SwiperSlide>
        ))}
        {showPagination &&
          <>
            <div className="swiper-button-prev" onClick={slideToPrev} >
              <LeftArrowSliderIcon />
            </div>
            <div className="swiper-button-next" onClick={slideToNext} >
              <RightArrowSliderIcon />
            </div>
          </>
        }
      </Swiper >
    </>
  );
}
