import * as React from 'react';
import Host from './host'
import Accordion from '@mui/material/Accordion';
import './Accordin.css'

function Channel({ channel, ind, advertisements, setfilteredAds, setemptyHost, setselectedChannel, setselectedHost }) {
    const channelClicked = () => {
        setemptyHost(false);
        setselectedHost('');
        setselectedChannel(channel.title);
        const filtered = advertisements.filter(ad => ad.channel.id === channel.id);
        setfilteredAds(filtered);
    }

    return (
        <>
            {channel?.host?.length === 0 ?
                <></> :
                <Accordion >
                    
                    {channel?.host && channel.host?.length > 0 ? channel?.host.map((host, ind) => {
                        return (
                            <>
                                <Host channelClicked={channelClicked} channel={channel} key={ind} host={host} ind={ind} advertisements={advertisements} setfilteredAds={setfilteredAds}
                                    setemptyHost={setemptyHost} setselectedHost={setselectedHost} />
                            </>
                        )
                    }) :
                        <div className='loading-text'> <h5>Host not available</h5> </div>
                    }
                    {/* <AccordionDetails>
                </AccordionDetails> */}
                </Accordion>
            }
        </>
    )
}

export default Channel
