import React, { useContext, useState, useEffect } from 'react'
import Classes from './styles/style.module.css'
import { MagnifierIcon, RightArrowIcon } from '../utils/SVGs/svgs'
import Card from '../Components/Card/Card';
import { userData } from '../Context/Context';
import Channel from './channel';
import { useParams } from 'react-router-dom';

function AllHosts() {
    const { url } = useContext(userData);
    const { type } = useParams();
    const [advertisements, setadvertisements] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredAds, setfilteredAds] = useState([]);
    const [loadingAds, setloadingAds] = useState(false);
    const [matchedChannels, setmatchedChannels] = useState([]);
    const [channels, setchannels] = useState([]);
    const [loadingChannels, setloadingChannels] = useState(false);
    const [emptyHost, setemptyHost] = useState(false);
    const [selectedChannel, setselectedChannel] = useState('All');
    const [selectedHost, setselectedHost] = useState('');
    const getAllChannelData = async () => {
        setloadingChannels(true);
        const res = await fetch(`${url}channel`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            }
        });
        const data = await res.json();
        // console.log(data);
        setloadingChannels(false);
        if (data.statusCode === 200) {
            setchannels(data.value);
            setloadingAds(true);
            const res2 = await fetch(`${url}advertisement/active`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                }
            });
            const data2 = await res2.json();
            setloadingAds(false);
            if (data2.statusCode === 200) {
                setadvertisements(data2.value);
                setfilteredAds(data2.value);
            }
        }
    }
    useEffect(() => {
        getAllChannelData();
    }, []);
    const matched = () => {
        console.log(matchedChannels);
    }
    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredAdvertisements = advertisements.filter(ad => ad.title.toLowerCase().includes(searchQuery.toLowerCase()));

    return (
        <>
            <section className={Classes.hero} >
                <div className={Classes.search} >
                    <input
                        name='search'
                        placeholder='Search to match the title of ads...'
                        type='text'
                        value={searchQuery}
                        onChange={handleSearch}
                    />
                    <div className={Classes.search_icon} >
                        <MagnifierIcon />
                    </div>
                </div>
                {searchQuery ?
                    <div className={Classes.main_category}>
                        <div className='flex_between mb10'>
                            <h2>Search Results</h2>
                        </div>
                        <div className={Classes.search_slider}>
                            {filteredAdvertisements && filteredAdvertisements.length > 0 ? filteredAdvertisements.map((ad, ind) => {
                                return (
                                    <Card key={ind} ad={ad} />
                                )
                            }) :
                                <h5>No matching advertisements found.</h5>
                            }
                        </div>
                    </div>
                    :
                    <></>
                }
                <div className={Classes.hero2} >
                    <div className={Classes.hero2_left} >
                        <div className={Classes.filters_55} >
                            <h4>Filters</h4>
                            <h5>Hosts</h5>
                        </div>
                        {loadingChannels &&
                            <div className='loading-text' > <h5>Loading</h5> </div>
                        }
                        {channels && channels.map((channel, ind) => {
                            return (
                                <Channel key={ind} channel={channel} advertisements={advertisements} setfilteredAds={setfilteredAds}
                                    setemptyHost={setemptyHost} setselectedChannel={setselectedChannel} setselectedHost={setselectedHost} />
                            )
                        })
                        }
                    </div>
                    <div className={Classes.hero2_right} >
                        <div className={`${Classes.applied_filters} ${Classes.filters_55}`} >
                            <div className={Classes.filter_box} >
                                <h5> {selectedChannel} </h5>
                            </div>
                            {selectedHost &&
                                <div className={Classes.filter_box} >
                                    <h5> {selectedHost} </h5>
                                </div>
                            }
                        </div>
                        <div className={Classes.h2_r_con} >
                            {loadingAds &&
                                <div className='loading-text' > <h5>Loading</h5> </div>
                            }
                            {filteredAds && !loadingAds && filteredAds.length > 0 ? filteredAds.map((ad, ind) => {
                                return (
                                    <>
                                        <Card key={ind} ad={ad} />
                                    </>
                                )
                            }) :
                                <>{!emptyHost && !loadingAds && <div className='loading-text' > <h5>No Ad in this channel</h5> </div>}</>
                            }
                            {emptyHost && !loadingAds &&
                                <div className='loading-text' > <h5>No Ad in this host</h5> </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AllHosts
