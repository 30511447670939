import React from 'react'
import Classes from './styles/style.module.css'
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function Host({ host, ind, advertisements, setfilteredAds, setemptyHost, setselectedHost, channel, channelClicked }) {
    const hostClicked = () => {
        setselectedHost(host.name)
        const filtered = advertisements.filter(ad => ad.host.id === host.id);
        setfilteredAds(filtered);
        if (filtered.length === 0) {
            setemptyHost(true);
        } else {
            setemptyHost(false);
        }
    }
    return (
        <>
            <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                onClick={channelClicked}
            >
                <h5 className={Classes.channel_active} onClick={hostClicked} > { host.name } </h5>
            </AccordionSummary>
        </>
    )
}

export default Host
