import React, { useRef, useEffect,useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import Classes from './styles/product.module.css'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import './styles.css';
import { Pagination, Navigation } from 'swiper/modules';
import image from '../../src/utils/images/image.webp'
import { LeftArrowSliderIcon, RightArrowSliderIcon } from '../utils/SVGs/svgs';
import SlideComp from './SlideComp';

export default function Slider({advertisements,AdData}) {
    const [Ads,setAds] = useState([
      {
          id: 1,
          title: 'title 1',
          channel:{
            type: 'FM'
          },
          image_url:[
            "https://res.cloudinary.com/ds4ima6xg/image/upload/v1710971408/radio_numbers/sjhgehwdfuym6w8kfa7h.jpg",
          ]
      },
      {
          id: 2,
          title: 'title 2',
          channel:{
            type: 'FM'
          },
          image_url:[
            "https://res.cloudinary.com/ds4ima6xg/image/upload/v1710971408/radio_numbers/sjhgehwdfuym6w8kfa7h.jpg",
          ]
      },
      {
          id: 3,
          title: 'title 3',
          channel:{
            type: 'AM'
          },
          image_url:[
            "https://res.cloudinary.com/ds4ima6xg/image/upload/v1710971408/radio_numbers/sjhgehwdfuym6w8kfa7h.jpg",
          ]
      },
      {
          id: 4,
          title: 'title 4',
          channel:{
            type: 'FM'
          },
          image_url:[
            "https://res.cloudinary.com/ds4ima6xg/image/upload/v1710971408/radio_numbers/sjhgehwdfuym6w8kfa7h.jpg",
          ]
      },
      {
          id: 5,
          title: 'title 4',
          channel:{
            type: 'FM'
          },
          image_url:[
            "https://res.cloudinary.com/ds4ima6xg/image/upload/v1710971408/radio_numbers/sjhgehwdfuym6w8kfa7h.jpg",
          ]
      },
      {
          id: 6,
          title: 'title 4',
          channel:{
            type: 'FM'
          },
          image_url:[
            "https://res.cloudinary.com/ds4ima6xg/image/upload/v1710971408/radio_numbers/sjhgehwdfuym6w8kfa7h.jpg",
          ]
      },
      {
          id: 7,
          title: 'title 4',
          channel:{
            type: 'FM'
          },
          image_url:[
            "https://res.cloudinary.com/ds4ima6xg/image/upload/v1710971408/radio_numbers/sjhgehwdfuym6w8kfa7h.jpg",
          ]
      },
      {
          id: 8,
          title: 'title 4',
          channel:{
            type: 'FM'
          },
          image_url:[
            "https://res.cloudinary.com/ds4ima6xg/image/upload/v1710971408/radio_numbers/sjhgehwdfuym6w8kfa7h.jpg",
          ]
      },
    ])
    const swiperRef = useRef(null);
    useEffect(() => {
        const intervalId = setInterval(() => {
          if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
            swiperRef.current.swiper.slideNext();
          }
        }, 5000);
      
        return () => {
          clearInterval(intervalId);
        };
    }, []);

  return (
    <div>
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        ref={swiperRef}
        autoplay={{ delay: 5000 }}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={{
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        }}
        modules={[Pagination, Navigation]}
        className={Classes.mySwiper}
      >
        {AdData && AdData.image_url.map((image) => (
          <SwiperSlide>
            <img className='slider_inner_img' src={image} />
          </SwiperSlide>
        ))}

        <div className="swiper-button-prev">
          <LeftArrowSliderIcon/>
        </div>
        <div className="swiper-button-next">
          <RightArrowSliderIcon/>
        </div>
      </Swiper>
    </div>
  );
}
