import React,{useContext, useState} from "react";
import { NavLink,useNavigate } from 'react-router-dom'
import Classes from "./styles/style.module.css";
import logo from '../utils/icons/logo.jpg'
import login from '../utils/images/login.png'
import { LineIcon } from "../utils/SVGs/svgs";
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { userData } from "../Context/Context";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CreateAd = ({handleCloseCreateAd}) => {
    const {url,userdata} = useContext(userData);
    const passwordRegex = /^(?=.*[A-Z]).{8,}$/;
    const [validFail,setvalidFail] = useState(false);
    const Navigate = useNavigate();
    const [images1, setImages1] = React.useState([]);
    const [imageURLs1, setImageURLs1] = React.useState([]);
    const handleImage1 = (e) => {
        const files = Array.from(e.target.files);
        setImages1([...images1, ...files]);

        const tempURLs = files.map((file) => URL.createObjectURL(file));
        setImageURLs1([...imageURLs1, ...tempURLs]);
    }
    const DeleteImage1 = (val,index) => {
        const updatedImages = [...images1];
        updatedImages.splice(index, 1);
        setImages1(updatedImages);
        
        const updatedImagesUrls = [...imageURLs1];
        updatedImagesUrls.splice(index, 1);
        setImageURLs1(updatedImagesUrls);
    };
    const [loading,setloading] = useState(false);
    const [open1,setopen1] = React.useState(false);
    const [open2,setopen2] = React.useState(false);
    const [open3,setopen3] = React.useState(false);
    const [formdata,setformdata] = useState({
      category: '',
      channel: '',
      host: '',
      location: '',
      city: '',
      address: '',
      title: '',
      description: '',
      business_email: '',
      contact: '',
      postal_code: '',
      ad_start_date: '',
      ad_start_time: '',
      ad_end_date: '',
      ad_end_time: '',
    });
    function changeValue(event){
      const {name,value} = event.target;
      setformdata({
        ...formdata,
        [name]: value,
      });
    }
    const submitform = async (event)=>{
      event.preventDefault();
      if(images1.length === 0) {
        setopen3(true);
        setTimeout(() => {
            setopen3(false);            
        }, 3000);
        return;
      }
      setloading(true);
      const formData = new FormData();
      formData.append('title', formdata.title);
      formData.append('description', formdata.description);
      formData.append('start_date', formdata.ad_start_date);
      formData.append('end_date', formdata.ad_end_date);
      formData.append('user_id', userdata.id);
      formData.append('channel_type', formdata.category);
      formData.append('channel_title', formdata.channel);
      formData.append('host_name', formdata.host);
      formData.append('start_time', formdata.ad_start_time);
      formData.append('end_time', formdata.ad_end_time);
      formData.append('email', formdata.business_email);
      formData.append('contact', formdata.contact);
      formData.append('country', formdata.location);
      formData.append('city', formdata.city);
      formData.append('address', formdata.address);
      formData.append('postal_code', formdata.postal_code);
      formData.append('province', '');
      formData.append('latitude', '');
      formData.append('longitude', '');
      
      for (let i = 0; i < images1.length; i++) {
        formData.append('images', images1[i]);
      }
      const res = await fetch(`${url}advertisement`, {
        method: 'POST',
        body: formData
      });
      const data = await res.json();
      setloading(false);
      console.log(data);
      if(data.statusCode === 201){
          setopen2(false);
          setopen1(true);
      }else{
          setopen1(false)
          setopen2(true);
      }
      setTimeout(() => {
          setopen1(false);
          setopen2(false);
      }, 3000);
  }
    const handlePopupClick = (e) => {
      e.stopPropagation(); // Prevent the click event from reaching the popupBackground
    };
    const handleBackgroundClick = (e) => {
      e.stopPropagation(); // Prevent the click event from reaching the popupBackground
      handleCloseCreateAd();
    };

  return (
    <>
      <>
        <Snackbar open={open1} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
          <Alert severity="success" variant="filled">
              Ad uploaded successfully!
          </Alert>
          </Snackbar>
          <Snackbar open={open2} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
          <Alert variant="filled" severity="error">
              Uploading Failed!
          </Alert>
          </Snackbar>
          <Snackbar open={open3} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
          <Alert variant="filled" severity="error">
              Please select atleast 1 image!
          </Alert>
        </Snackbar>
      </>
    <div className={Classes.popupBackground} onClick={handleBackgroundClick}>
      <form onSubmit={submitform} className={Classes.popup} onClick={handlePopupClick}>
        <div className={Classes.popupLeft}>
          <div className={Classes.form} >
                <label> <h5>Upload upto 20 photos</h5> </label>
                <div className="flex_align" >
                  <IconButton color="primary" aria-label="upload picture" style={{marginLeft:'5px'}} component="label">
                    <input 
                      hidden 
                      accept="image/*" 
                      multiple
                      type="file"
                      onChange={handleImage1}                            
                      />
                    <PhotoCamera />
                  </IconButton>
                  {imageURLs1.map((val, index) => (
                    <div style={{position:'relative'}} >
                      <img 
                        style={{height:"2.5rem",margin:"3px 3px",borderRadius:"9px"}} 
                        key={index} src={val} alt={`Image ${index + 1}`} 
                      />
                      { images1 && images1.length>1 &&
                        <svg onClick={()=>{DeleteImage1(val,index)}} style={{position:'absolute',top:'4px',right:'4px',fill:'white', cursor:'pointer'}} xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"/></svg>
                      }
                    </div>
                  ))}
                </div>
                <label> <h5>Category</h5> </label>
                <select
                  name='category'
                  value={formdata.category}
                  onChange={changeValue}
                >
                  <option>FM</option>
                  <option>AM</option>
                </select>
                <label> <h5>Channel</h5> </label>
                <input 
                  placeholder='Channel'
                  name='channel'
                  type='text'
                  value={formdata.channel}
                  onChange={changeValue}
                  required
                />
                <label> <h5>Host</h5> </label>
                <input 
                  placeholder='Host'
                  name='host'
                  type='text'
                  value={formdata.host}
                  onChange={changeValue}
                  required
                />
                <label> <h5>Location</h5> </label>
                <select
                  name='location'
                  value={formdata.location}
                  onChange={changeValue}
                >
                  <option>Select Host</option>
                  <option>Canada</option>
                </select>
                
                <label> <h5>City</h5> </label>
                <input 
                  placeholder='Enter your city'
                  name='city'
                  type='text'
                  value={formdata.city}
                  onChange={changeValue}
                  required
                />
                
                <label> <h5>Address</h5> </label>
                <input 
                  placeholder='Enter your address'
                  name='address'
                  type='text'
                  value={formdata.address}
                  onChange={changeValue}
                  required
                />

                <label> <h5>Postal Code</h5> </label>
                <input 
                  placeholder='Enter your postal code'
                  name='postal_code'
                  type='text'
                  value={formdata.postal_code}
                  onChange={changeValue}
                  required
                />

          </div>
        </div>
        <div className={Classes.popupRight}>
          <div className={Classes.form} >
                
                <label><h5>Ads Title</h5></label>
                <input 
                    placeholder='Enter title of Ad'
                    name='title'
                    type='text'
                    value={formdata.title}
                    onChange={changeValue}
                    required
                />
                
                <label><h5>Describe what you are Advertising?</h5></label>
                <textarea 
                    placeholder='Enter description'
                    rows={5}
                    name='description'
                    type='text'
                    value={formdata.description}
                    onChange={changeValue}
                    required
                />

                <label><h5>Business Email</h5></label>
                <input 
                    placeholder='Enter your email'
                    name='business_email'
                    type='email'
                    value={formdata.business_email}
                    onChange={changeValue}
                    required
                />

                <label><h5>Contact</h5></label>
                <input 
                    placeholder='Enter your Contact'
                    name='contact'
                    type='number'
                    value={formdata.contact}
                    onChange={changeValue}
                    required
                />
                
                <label><h5>Ad start time</h5></label>
                <div className="flex_between" >
                  <input 
                      placeholder='dd/mm/yyyy'
                      name='ad_start_date'
                      type='date'
                      value={formdata.ad_start_date}
                      onChange={changeValue}
                      required
                  />
                  <input 
                    placeholder='time'
                    name='ad_start_time'
                    type='time'
                    value={formdata.ad_start_time}
                    onChange={changeValue}
                    required
                  />
                </div>

                <label><h5>Ad end time</h5></label>
                <div className="flex_between" >
                  <input 
                      placeholder='dd/mm/yyyy'
                      name='ad_end_date'
                      type='date'
                      value={formdata.ad_end_date}
                      onChange={changeValue}
                      required
                  />
                  <input 
                    placeholder='time'
                    name='ad_end_time'
                    type='time'
                    value={formdata.ad_end_time}
                    onChange={changeValue}
                    required
                  />
                </div>
                <button type='submit' disabled={loading} > { loading? 'Submitting':'Submit' } </button>
          </div>
        </div>
        <svg
          className={Classes.xMark}
          onClick={handleCloseCreateAd}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
        >
          <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
        </svg>
        <div className={Classes.line_con} >
          <LineIcon />
        </div>
      </form>
    </div>
  </>
  );
};

export default CreateAd;
