import React, {useContext,useState,useEffect} from 'react'
import Classes from './styles/product.module.css'
import Slider from './Slider'
import { PhoneIcon, WhatsAppIcon } from '../utils/SVGs/svgs'
import { useParams } from 'react-router-dom'
import { userData } from '../Context/Context'

function Product() {
    const {url} = useContext(userData);
    const {id} = useParams();
    const [loading,setloading] = useState(false);
    const [AdData,setAdData] = useState(null);
    const getAdData = async ()=>{
        setloading(true);
        const res = await fetch(`${url}advertisement/active`,{
            method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type":"application/json",
            }
        });
        const data = await res.json();
        setloading(false);
        if(data.statusCode === 200){
            const filtered = data.value.filter(ad => ad.id === id);
            setAdData(filtered[0]);
            console.log(filtered[0]);
        }
    }
    useEffect(()=>{
        getAdData();
    },[])

  return (
    <>
    { loading?
        <div className={Classes.hero_loading} >
            <div className='loading-text'><h5>Loading...</h5></div>
        </div>
        :
        <div className={Classes.hero} >
            <Slider AdData={AdData} />
            <div className={Classes.h1_right} >
            <div className={Classes.h1_card} >
                <div>
                    <div className={Classes.row1} >
                        <div className={Classes.width5} >
                            <h5>Title:</h5>
                        </div>
                        <h5> {AdData?.title} </h5>
                    </div>
                    <div className={Classes.row1} >
                    <div className={Classes.width5} >
                        <h5>Ads end date:</h5>
                    </div>
                    <h5> {new Date(AdData?.end_date).toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })} </h5>
                    </div>
                    <div className={Classes.row1} >
                    <div className={Classes.width5} >
                        <h5>Address:</h5>
                    </div>
                    <h5>{AdData?.address}</h5>
                    </div>
                    <div className={Classes.row1} >
                    <div className={Classes.width5} >
                        <h5>City/Country:</h5>
                    </div>
                    <h5>{AdData?.city}, {AdData?.country}</h5>
                    </div>
                    <div className={Classes.row1} >
                    <div className={Classes.width5} >
                        <h5>Postal Code:</h5>
                    </div>
                    <h5>{AdData?.postal_code}</h5>
                    </div>
                    <div className={Classes.row1} >
                    <div className={Classes.width5} >
                        <h5>Host:</h5>
                    </div>
                    <h5>{AdData?.host?.name}</h5>
                    </div>
                    <div className={Classes.width5} >
                    <h5>Description:</h5>
                    </div>
                    <h5 className={Classes.mb10} >{AdData?.description}</h5>
                </div>
                <div className={Classes.buttons} >
                    <div className={Classes.whatsapp_btn} >
                        <WhatsAppIcon/>
                        <h5>WhatsApp</h5>
                    </div>
                    <div className={Classes.phone_btn} >
                        <PhoneIcon/>
                        <h5>Message</h5>
                    </div>
                </div>
            </div>
            </div>
        </div>
    }
    </>
  )
}

export default Product
