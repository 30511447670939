import React, {useContext, useState, useEffect} from 'react'
import { NavLink } from 'react-router-dom'
import Classes from './styles/style.module.css'
import logo from '../utils/icons/logo.jpg'
import { ProfileIcon } from '../utils/SVGs/svgs'
import LoginPopup from '../Login/LoginPopup'
import SignupPopup from '../Signup/SignupPopup'
import CreateAd from '../CreateAd/CreateAd'
import { userData } from '../Context/Context'

function Navbar() {
  const {islogged,logout,userdata} = useContext(userData);
  const [showLoginPopup,setshowLoginPopup] = useState(false);
  const handleCloseLoginPopup = ()=>{
    setshowLoginPopup(false);
  }
  const handleOpenLoginPopup = ()=>{
    setshowLoginPopup(true);
  }
  const [showSignupPopup,setshowSignupPopup] = useState(false);
  const handleCloseSignupPopup = ()=>{
    setshowSignupPopup(false);
  }
  const handleOpenSignupPopup = ()=>{
    setshowSignupPopup(true);
  }
  const [openCreadAdPopup,setopenCreadAdPopup] = useState(false);
  const handleCloseCreateAd = ()=>{
    setopenCreadAdPopup(false);
  }

  const [showUsername, setShowUsername] = useState(window.innerWidth > 480);

  useEffect(() => {
    const handleResize = () => {
      setShowUsername(window.innerWidth >= 480);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  return (
    <>
      <div className={Classes.nav_con} >
        <div className={Classes.nav_inner} >
          <div className={Classes.nav_left} >
            <div className={Classes.nav_img} >
              <img src={logo} alt='logo' onClick={() => window.location.href = '/'}  />
            </div>
            <div className={Classes.nav_elem} >
                <NavLink to='/' className={Classes.elem} >
                  <h5>Home</h5>
                </NavLink>
                <NavLink to='/category/all' className={Classes.elem} >
                  <h5>Category</h5>
                </NavLink>
                { islogged &&
                  <div onClick={()=>{setopenCreadAdPopup(true)}} className={Classes.elem} >
                    <h5>Create Ad</h5>
                  </div>
                }
              </div>
          </div>
          <div className={Classes.nav_right} >
            { islogged ?
                <>
                  { userdata && showUsername && <h5 className={Classes.username} >{ userdata.first_name + ' ' + userdata.last_name }</h5>}
                  <h5 onClick={logout} >Logout</h5>
                </>
                :<>
                  <h5 onClick={()=>{setshowSignupPopup(true)}} >Signup</h5>
                  <h5 onClick={()=>{setshowLoginPopup(true)}} >Login</h5>
                </>
            }
            {/* <ProfileIcon /> */}
          </div>
        </div>
      </div>
      { showLoginPopup &&
        <LoginPopup 
          handleCloseLoginPopup={handleCloseLoginPopup}
          handleOpenSignupPopup={handleOpenSignupPopup}
        />
      }
      { showSignupPopup &&
        <SignupPopup 
          handleOpenLoginPopup={handleOpenLoginPopup}
          handleCloseSignupPopup={handleCloseSignupPopup}
        />
      }
      { openCreadAdPopup &&
        <CreateAd handleCloseCreateAd={handleCloseCreateAd} />
      }
    </>
  )
}

export default Navbar
